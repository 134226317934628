export const getInitialStoreState = () => {
    return {
        access_token: undefined,
        user_type: undefined,
        statusCode: 200,

    }
};

export function authReducer(state, action) {
    switch (action.type) {
        case 'access_token':
            window.localStorage.setItem('access_token', action.access_token || '');
            return { ...state, access_token: action.access_token };
        case 'user_type':
            return { ...state, user_type: action.user_type };
        case 'logout':
            window.localStorage.removeItem('access_token');
            return { ...state, access_token: undefined, user_type: undefined };
        default:
            return { ...state };
    }
}
