import React, {
  createContext,
  Suspense,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import "./App.scss";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import { Alert, Box, Link } from "@mui/material";
import { CustomLoader } from "./helpers/CustomLoader";
import { authReducer, getInitialStoreState } from "./helpers/Authprovider";
import dayjs from "dayjs";
import axios from "axios";

const HomePage = React.lazy(() => import("./Pages/HomePage/HomePage"));
const ForSchool = React.lazy(() => import("./Pages/ForSchool/ForSchool"));
const Contact = React.lazy(() => import("./Pages/ContactUs/ContactUs"));
const AboutUs = React.lazy(() => import("./Pages/AboutUs/AboutUs"));
const Results = React.lazy(() => import("./Pages/Performance/Performance"));
const TeacherHome = React.lazy(() => import("./Pages/TeacherHome/TeacherHome"));
const StudentHome = React.lazy(() => import("./Pages/StudentHome/StudentHome"));
const Login = React.lazy(() => import("./Pages/Login/Login"));
const PrivacyPolicy = React.lazy(() =>
  import("./Pages/PrivacyPolicy/PrivacyPolicy")
);
const TermsAndConditions = React.lazy(() =>
  import("./Pages/TermsAndConditions/TermsAndConditions")
);

export const AppStateContext = createContext(getInitialStoreState());
export const AppDispatchContext = createContext({});

export default function App() {
  const location = useLocation();
  const isTeacherHome = location.pathname === "/teacher-home";
  const isStudentHome = location.pathname === "/student-home";
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(authReducer, getInitialStoreState());

  const accessToken = localStorage.getItem("access_token");
  const expiryToken = localStorage.getItem("access_expiry");
  const user_type = localStorage.getItem("user_type");

  useEffect(() => {
    if (accessToken && expiryToken) {
      const tokenExpiry = dayjs(expiryToken);
      const now = dayjs();

      if (tokenExpiry.isBefore(now)) {
        dispatch({ type: "logout" });
      } else {
        dispatch({ type: "access_token", access_token: accessToken });
        dispatch({ type: "user_type", user_type: user_type });
      }
    }
    setLoading(false);
  }, [accessToken, expiryToken, user_type]);

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <AppDispatchContext.Provider value={dispatch}>
      <AppStateContext.Provider value={state}>
        {!isTeacherHome && !isStudentHome && <Header />}
        <Suspense fallback={<CustomLoader />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/school" element={<ForSchool />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/performance" element={<Results />} />
            <Route
              path="/login"
              element={
                <RequireAuth redirect={"/"} noAuth>
                  <Login />
                </RequireAuth>
              }
            />
            <Route
              path="/teacher-home"
              element={
                <RequireAuth user_type="teacher" redirect="/login">
                  <TeacherHome />
                </RequireAuth>
              }
            />
            <Route
              path="/student-home"
              element={
                <RequireAuth user_type="student" redirect="/login">
                  <StudentHome />
                </RequireAuth>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsAndConditions />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        {!isTeacherHome && !isStudentHome && <Footer />}
      </AppStateContext.Provider>
    </AppDispatchContext.Provider>
  );
}

function NotFound() {
  return (
    <Box
      sx={{
        display: "flex",
        margin: "128px auto",
        justifyContent: "center",
      }}
    >
      <Alert variant={"filled"} color={"warning"}>
        404 - Page Not Found <Link href={"/"}>click here</Link> for the home
        page
      </Alert>
    </Box>
  );
}

const RequireAuth = ({ noAuth, user_type, redirect, children }) => {
  const { access_token, ...rest } = useContext(AppStateContext);
  const saved_user_type = rest.user_type;
  if (noAuth) {
    if (!access_token) {
      return children;
    }
    return <Navigate replace to={redirect} />;
  }

  if (access_token && saved_user_type === user_type) {
    return children;
  }

  return <Navigate replace to={redirect || "/"} />;
};
