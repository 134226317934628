import React from "react";
import "./Footer.scss";
import {Container} from "@mui/material";
import {Link} from "react-router-dom";
import {useAppContext} from "../../helpers/AppContext";
import {
    Facebook,
    Instagram,
    LinkedIn,
    Mail,
    WhatsApp,
    YouTube,
} from "@mui/icons-material";

const Footer = () => {
    const {handleScroll, handleRegisterFormClick} = useAppContext();

    const navItems = [
        {label: "Home", path: "/"},
        {label: "For Schools", path: "/school"},
        {label: "Performance", path: "/performance"},
        {label: "Register", path: "/", onClick: handleRegisterFormClick},
        {label: "About Us", path: "/about"},
        {label: "Contact Us", path: "/contact"},
    ];

    const socialMediaLinks = [
        {icon: <Instagram/>, label: "Instagram", url: "https://www.instagram.com/praccel.app/"},
        {icon: <YouTube/>, label: "YouTube", url: "https://www.youtube.com/@PraccelApp"},
        {icon: <Facebook/>, label: "Facebook", url: "https://www.facebook.com/Praccel/"},
        {icon: <LinkedIn/>, label: "LinkedIn", url: "https://www.linkedin.com/company/praccel/"},
    ];

    return <footer className="footer">
        <Container maxWidth="xl">
            <div className="content">
                <div className="logo">
                    <h2>Paleru Technologies Pvt. Ltd.</h2>
                    <p>Kavitha Kunj, Kalbavi road,</p>
                    <p>Kottara, Mangalore, India.</p>
                    <p>
                        <a href={"mailto:karthik@praccel.com"} target="_blank" rel="noopener noreferrer">
                            <Mail/> {" "} karthik@praccel.com
                        </a>

                    </p>
                    <p>
                        <a href={"https://wa.me/918951621660"} target="_blank" rel="noopener noreferrer">
                            <WhatsApp/> {" "} +91 8951621660
                        </a>

                    </p>
                </div>
                <nav className="nav">
                    <ul>
                        {navItems.map((item, index) => (
                            <li key={index} onClick={item.onClick || handleScroll}>
                                <Link to={item.path}>{item.label}</Link>
                            </li>
                        ))}
                    </ul>
                </nav>
                <div>
                    {socialMediaLinks.map((link, index) => (
                        <p key={index}>
                            <a href={link.url} target="_blank" rel="noopener noreferrer">
                                {link.icon} {link.label}
                            </a>
                        </p>
                    ))}
                </div>
            </div>
            <div className="bottom">
                <p>&copy; Paleru Technologies Pvt. Ltd. {new Date().getFullYear()}. All rights reserved.</p>
            </div>
        </Container>
    </footer>
};

export default Footer;
