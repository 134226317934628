import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {AppProvider} from "./helpers/AppContext";

import { createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
    palette: {
        // primary: {
        //     main: '#0A57FF',
        // },
    },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <AppProvider>
                <App/>
            </AppProvider>
        </ThemeProvider>


    </BrowserRouter>
)

