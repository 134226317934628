import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

export const AppContext = createContext();

export const AppProvider = ({children}) => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const handleGoBack = () => {
        navigate(-1);
        window.scrollTo({top: 0, behavior: "smooth"});
    };

    const videoRef = useRef(null);

    const handleVideoEnd = () => {
        if (!videoRef.current.ended) {
            videoRef.current.pause();
        }
    };

    const handleScroll = () => {
        if (window.pageYOffset !== 0) {
            window.scrollTo({top: 0});
        }
    };

    const handleRegisterFormClick = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSdcxiAE8qrgwczxEHLDE8dD_cG43BJPFavBNILVWPCoKITpOw/viewform", "_blank");
    };
    const playStoreLink = () => {
        window.open("https://play.google.com/store/apps/details?id=com.praccel.app", "_blank");
    };

    return <AppContext.Provider
        value={{handleGoBack, handleScroll, handleVideoEnd, videoRef, isMobile, handleRegisterFormClick,playStoreLink}}
    >
        {children}
    </AppContext.Provider>
};

export const useAppContext = () => {
    const appContext = useContext(AppContext);

    if (!appContext) {
        throw new Error("useAppContext must be used within an AppProvider");
    }

    return appContext;
};
