import React, {useState, useEffect, useRef, useContext} from "react";
import {Link, NavLink} from "react-router-dom";
import "./header.scss";
import logo from "../../Assets/Images/Home/Logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {Container, IconButton} from "@mui/material";
import {useAppContext} from "../../helpers/AppContext";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {AppStateContext} from "../../App";


export default function Header() {
    const {handleScroll, handleRegisterFormClick} = useAppContext();
    const [showNavLinks, setShowNavLinks] = useState(false);
    const [isScrolledUp, setIsScrolledUp] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const {access_token, user_type} = useContext(AppStateContext);

    const toggleNavLinks = () => {
        setShowNavLinks(!showNavLinks);
    };

    const handleLogoClick = () => {
        setShowNavLinks(false);
        handleScroll();
    };

    const handleNavLinkClick = () => {
        setShowNavLinks(false);
        window.scrollTo({top: 0, behavior: "smooth"});
    };

    const handleRegisterClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleExpandMoreClick = (event) => {
        event.stopPropagation();
        setShowDropdown(!showDropdown);
    };

    const handleRegisterFormItemClick = () => {
        setShowDropdown(false);
        handleRegisterFormClick();
    };

    const handleOutsideClick = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setShowDropdown(false);
        }
    };

    let navLinks = [
        {to: "/", label: "Home"},
        {to: "/school", label: "For Schools"},
        {to: "/performance", label: "Performance"},
        {label: "Register", dropdown: true},
        ...(access_token && user_type === "student") ? [{to: "/student-home", label: "Student Login"}] : [],
        ...(access_token && user_type === "teacher") ? [{to: "/teacher-home", label: "Teacher Login"}] : [],
        ...((!access_token || !user_type) ? [{to: "/login", label: "Login"}] : []),
        {to: "/about", label: "About Us"},
        {to: "/contact", label: "Contact Us"},

    ];
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolledUp(window.scrollY > 0);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);


    return <Container maxWidth="xl">
        <div className={`header ${isScrolledUp ? "scrolled" : ""}`}>
            <Link to="/" onClick={handleLogoClick}>
                <img src={logo} alt="logo"/>
            </Link>
            <IconButton
                className="nav-toggle"
                onClick={toggleNavLinks}
                color="primary"
            >
                {showNavLinks ? (
                    <CloseIcon onClick={handleRegisterClick}/>
                ) : (
                    <MenuIcon/>
                )}
            </IconButton>
            <ul className={`nav-links ${showNavLinks ? "show" : ""}`}>
                {navLinks.map(({to, label, dropdown}) =>
                    dropdown ? (
                        <li
                            key={label}
                            className={`dropdown ${showDropdown ? "d-show" : ""}`}
                            ref={dropdownRef}
                        >
                        <span onClick={handleRegisterClick}>{label}{" "}
                            {showDropdown ? (
                                <ExpandLessIcon onClick={handleExpandMoreClick}/>
                            ) : (
                                <ExpandMoreIcon onClick={handleExpandMoreClick}/>
                            )}
                        </span>
                            {showDropdown && (
                                <ul className="dropdown-menu">
                                    <li onClick={handleRegisterFormItemClick}>
                                        Registration Form
                                    </li>
                                </ul>
                            )}
                        </li>
                    ) : (
                        <li key={to} onClick={handleNavLinkClick}>
                            <NavLink exact={to === "/"} to={to} activeClassName="active">
                                {label}
                            </NavLink>
                        </li>
                    )
                )}
            </ul>
        </div>
    </Container>
}
